
export default {
	name: 'craOrgStatistics',
	data() {
		return {
			count1: 0,
			count2: 0,
			count3: 0,
			count4: 0,
			count5: 0,
			x: [],
			y: [],
			token: '',
			userId: '',
			value1: '',
			value2: '',
			value3: '',
			value4: '',
			options: [],
			list: [],
			echar4List: [],
		}
	},

	filters: {
		stateFilter(data) {
			if (data == 1) {
				data = '待启动';
			} else if (data == 2) {
				data = '进行中';
			} else if (data == 3) {
				data = '中心关闭';
			} else {
				data = '初始状态'
			}
			return data;
		}
	},
	mounted() {
		this.drawLine();
	},
	created() {
		var _this = this;
		this.token = this.getCookie('token')
		this.userId = this.getCookie('userId')
		_this.getProjectsts()
		_this.getAuditHdCount()
		_this.getDepartmentList()
		this.getTableList()
	},
	beforeRouteLeave(to, from, next) {
		to.meta.keepAlive = false;
		next();
	},
	methods: {
		getProjectsts() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/homeQuantitativeStatistics', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.count1 = response.data.data.projectNum;
					_this.count2 = response.data.data.JCNum;
					_this.count3 = response.data.data.JCAllTime;
					_this.count4 = response.data.data.auditNum;
					_this.count5 = response.data.data.auditTime;

				} else if (response.data.code == 401) {
					_this.$router.push('/')
				}
			})
		},
		//科室下拉框
		getDepartmentList() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/findDepartmentList', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.options = response.data.data.array;
					_this.options.unshift({ 'name': '全部', 'id': '' })
				}
			})
		},
		value1Change() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/jchdCount', {
				departmentId: _this.value1
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar1(response.data.data)
				}
			})
		},
		value2Change() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/statisticsJCTime', {
				departmentId: _this.value2
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar2(response.data.data)
				}
			})
		},
		value3Change() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/auditHdCount', {
				departmentId: _this.value3
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar3(response.data.data)
				}
			})
		},
		value4Change() {
			this.getAuditHdCount()
		},
		getAuditHdCount() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/specificActivityCount', {
				departmentId: _this.value4
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.echar4List = response.data.data.array;
				}
			})
		},
		getTableList() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/reportStatisticsFrequency', {
				departmentId: '',
				page: '1',
				state: '',
				projectName: '',
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.list = response.data.data.objArray;
					_this.list = _this.list.slice(0, 5);
				}
			})
		},
		//点击跳转详情
		projectParticulars(id) {
			this.$router.push({
				name: 'inspectParticulars',
				params: {
					id: id
				}
			})
		},
		getEchar1(data) {
			let echar1 = this.$echarts.init(document.getElementById('echar-1'))
			echar1.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['单项目监查工时', '平台平均单项目监查工时']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 100,
					interval: 20,
				},
				series: [{
					name: '单项目监查工时',
					type: 'bar',
					barWidth: '70%',
					color: '#539CF2',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				}, {
					name: '平台平均单项目监查工时',
					type: 'line',
					color: '#FD9F83',
					data: data.y2
				},]
			});
		},
		getEchar2(data) {
			let echar2 = this.$echarts.init(document.getElementById('echar-2'))
			echar2.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['项目名']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}

				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 300,
					interval: 100,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				series: [{
					name: '项目名',
					type: 'bar',
					barWidth: '70%',
					color: '#FFE188',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y
				}]
			});
		},
		getEchar3(data) {
			let echar3 = this.$echarts.init(document.getElementById('echar-3'))
			echar3.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['单项目稽查工时', '平台平均单项目季度稽查工时']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				yAxis: {
					type: 'value',
				},
				series: [{
					name: '单项目稽查工时',
					type: 'line',
					color: '#FEDA66',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				},
				{
					name: '平台平均单项目季度稽查工时',
					type: 'line',
					color: '#52D9DB',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y2
				}
				]
			});
		},
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// 绘制图表
			_this.$http.post(_this.craUrl + '/jchdCount', {
				departmentId: _this.value1
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar1(response.data.data)
				}
			})

			// 绘制图表
			_this.$http.post(_this.craUrl + '/statisticsJCTime', {
				departmentId: _this.value2
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar2(response.data.data)
				}
			})
			// 绘制图表
			_this.$http.post(_this.craUrl + '/auditHdCount', {
				departmentId: _this.value3
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar3(response.data.data)
				}
			})
		},
		projectTabel(id) {
			this.$router.push({ name: 'inspectParticulars', params: { id: id } })
		},
		toCraProject(id) {
			if (id) {
				this.$router.push({ name: 'workingTime', params: { id: id } })
			} else {
				this.$router.push({ name: 'workingTime', params: { id: 0 } })
			}

		},
	}
}
